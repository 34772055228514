import className from 'classnames';

function Button ({
    children,
    primary,
    secondary,
    success,
    warning,
    danger,
    rounded,
    outline,
    ...rest
}) {

    const classes = className(rest.className, 'w-40 mt-3 py-1', {
        'bg-sky-600 text-white': primary,
        'bg-black text-white': secondary,
        'bg-green-600 text-white': success,
        'bg-yellow-600 text-white': warning,
        'bg-red-600 text-white': danger,
        'rounded-full': rounded,
        'bg-white text-black border-black border w-40 mt-3 py-1': outline
    });


    return <button {...rest} className={classes}>{children}</button>
}

Button.propTypes = {
    checkPurposeValue: ({ primary, secondary, success, warning, danger }) => {
        const count = Number(!!primary)
        + Number(!!secondary)
        + Number(!!warning)
        + Number(!!success)
        + Number(!!danger)

        if( count > 1 ) {
            return new Error (
                'Only one main class allowed per button - conflicting styles may occur',
            );
        }

    }
};

export default Button;