import Accordion from '../components/Accordion';

function AccordionPage() {
  const items = [
    {
      id: 'dsfsfes',
      label: 'Accordion Label',
      content: 'Content for the accordion here'
    },
    {
      id: 'dsfjfhes',
      label: 'Accordion Label2',
      content: 'Content for the accordion here'
    },
    {
      id: 'dssdgr4es',
      label: 'Accordion Label3',
      content: 'Content for the accordion here'
    },
    {
      id: 'dpppes',
      label: 'Accordion Label4',
      content: 'Content for the accordion here'
    },
    {
      id: 'daqwees',
      label: 'Accordion Label5',
      content: 'Content for the accordion here'
    },
  ]




  return <Accordion items={items} />
}

export default AccordionPage;