import Button from '../components/Button';

function ButtonPage() {
  return (
    <div className="buttonWrap">
      <div>
        <Button primary>Primary Button</Button>
      </div>
      <div>
        <Button secondary rounded>Secondary Button</Button>
      </div>
      <div>
        <Button success>Success Button</Button>
      </div>
      <div>
        <Button warning rounded>Warning Button</Button>
      </div>
      <div>
        <Button danger>Danger Button</Button>
      </div>
      <div>
        <Button outline>Danger Button</Button>
      </div>
    </div>
  );
}

export default ButtonPage;
